import Metamask from './WalletIcons/metamaskWallet.png';
import WalletConnect from './WalletIcons/wallet-connect.svg';
import FortmaticLogo from './WalletIcons/Fortmatic-icon.svg';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';

const RPC_URLS = {
  1: 'https://mainnet.infura.io/v3/a2bffde9baac4c9689fe2ce9ef2a9da1',
  4: 'https://rinkeby.infura.io/v3/a2bffde9baac4c9689fe2ce9ef2a9da1',
  80001: 'https://polygon-mumbai.infura.io/v3/cb69790f7bcf4870bed74530bdf4c7aa',
  137: 'https://polygon-mainnet.infura.io/v3/cb69790f7bcf4870bed74530bdf4c7aa',
};

const SUPPORTED_CHAIN_IDS = [1, 4, 3, 42, 5, 56, 97, 137, 80001, 56, 97, 1337];

//metamask
export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: RPC_URLS[1],
    4: RPC_URLS[4],
    80001: RPC_URLS[80001],
    137: RPC_URLS[137],
  },
  qrcode: true,
  pollingInterval: 15000,
});

export function resetWalletConnector(connector) {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
}
export const fortmatic = new FortmaticConnector({
  apiKey: 'pk_test_F78278F15543D271',
  chainId: 4,
});

//coinbase
export const walletlink = new WalletLinkConnector({
  url: [RPC_URLS[80001], RPC_URLS[137]],
  appName: 'animal-vesting-portal',
  supportedChainIds: [1, 4, 80001, 137],
});

export const connectors = [
  {
    title: 'Metamask',
    icon: Metamask,
    provider: injected,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    provider: walletconnect,
    priority: 2,
  },
  {
    title: 'CoinBase',
    icon: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
    provider: walletlink,
    priority: 3,
  },
  {
    title: 'Fortmatic',
    icon: FortmaticLogo,
    provider: fortmatic,
    priority: 999,
  },
  // {
  //   title: 'TokenPocket',
  //   icon: TokenPocket,
  //   connectorId: 'injected',
  //   priority: 999,
  // },
  // {
  //   title: 'SafePal',
  //   icon: SafePal,
  //   connectorId: 'injected',
  //   priority: 999,
  // },
  // {
  //   title: 'Coin98',
  //   icon: Coin98,
  //   connectorId: 'injected',
  //   priority: 999,
  // },
];
