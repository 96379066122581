import { Link as ReactLink } from 'react-router-dom';
import '@fontsource/questrial';

import {
  Box,
  Image,
  Flex,
  Link,
  Button,
  useColorModeValue,
  Stack,
  useColorMode,
  Text,
  useDisclosure,
  Img,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, HamburgerIcon } from '@chakra-ui/icons';

import img from '../assets/ANIMAL_CONCERTS_Stacked_Original-Color-V1-1.png';
import metamaskLogo from './Account/WalletIcons/metamaskWallet.png';

import Account from './Account/Account';
import { useWeb3React } from '@web3-react/core';

export default function Navbar(props) {
  const { account, activate, deactivate, chainId } = useWeb3React();

  const { colorMode, toggleColorMode } = useColorMode();
  const hoverColor = useColorModeValue('blue.500', 'cyan.500');
  const linkColor = useColorModeValue('#532DF5', 'white');
  const boxColor = useColorModeValue('white', '#070b2f');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());

  const addTokenToMetamask = async () => {
    const tokenAddress = '0xecc4176b90613ed78185f01bd1e42c5640c4f09d';
    const tokenSymbol = 'ANML';
    const tokenDecimals = 18;
    const tokenImage = 'https://i.imgur.com/mlhBV5l.png';
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex
      zIndex={10}
      as='nav'
      align='center'
      justify='space-between'
      wrap='wrap'
      padding={6}
      sx={{
        position: 'sticky',
        top: '0',
      }}
      bg={boxColor}
      {...props}
    >
      <Flex align='center' mr={5}>
        <Link
          as={ReactLink}
          to='/'
          _focus={{
            border: 'none',
          }}
        >
          <Image height='50' width='120' src={img} alt='logo' />
        </Link>
      </Flex>
      <Button
        marginLeft='auto'
        display={{ base: 'block', md: 'none' }}
        onClick={toggleColorMode}
        colorScheme={'messenger'}
        borderRadius={'2xl'}
        variant='ghost'
      >
        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
      </Button>

      <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
        <HamburgerIcon />
      </Box>

      <Stack
        direction={[
          'column-reverse',
          'row-reverse',
          'row-reverse',
          'row-reverse',
        ]}
        display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
        width={{ base: 'full', md: 'auto' }}
        alignItems={['start', 'end', 'end', 'end']}
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
        mr={15}
      >
        <Link
          fontWeight={'light'}
          color={linkColor}
          variant={'nav'}
          as={ReactLink}
          px={1}
          py={1}
          _focus={{
            border: 'none',
            color: hoverColor,
          }}
          _hover={{
            color: hoverColor,
          }}
          fontSize={'lg'}
          to='/'
        >
          <Text fontFamily={'Questrial'}>Home</Text>
        </Link>
      </Stack>

      <Box
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
        mt={{ base: 4, md: 0 }}
      >
        {account && (
          <Button margin={'20px'} onClick={addTokenToMetamask}>
            Add $ANML to{' '}
            <Img height={'24px'} marginLeft={'8px'} src={metamaskLogo} />
          </Button>
        )}
        <Account nav />

        <Button
          display={{ base: isOpen ? 'none' : 'none', md: 'inline-block' }}
          marginRight={'2'}
          onClick={toggleColorMode}
          colorScheme={'messenger'}
          borderRadius={'2xl'}
          variant='ghost'
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </Box>
    </Flex>
  );
}
