/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { formatEther } from '@ethersproject/units';

import AnimalAllocation from '../../contracts/Vesting.sol/Vesting.json';

import {
  Heading,
  Text,
  Flex,
  Button,
  Box,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import '@fontsource/questrial';
import { WarningTwoIcon } from '@chakra-ui/icons';
import DetailedInfo from './DetailedInfo';
import { useWeb3React } from '@web3-react/core';

const AnimalAllocationAddress = process.env.REACT_APP_VESTING_ADDRESS;
const AnimalTokenAddress = process.env.REACT_APP_ANIMAL_ADDRESS;

const SummaryCard = () => {
  const { account, library, chainId, active } = useWeb3React();

  const toast = useToast();

  const [totalShares, setTotalShares] = useState();
  const [outstandingShares, setOutStandingShares] = useState();
  const [unlockedShares, setUnlockedShares] = useState();
  const [withdrawnShares, setWithdrawnShares] = useState();
  const [loading, setLoading] = useState(null);

  const [error, SetError] = useState(null);

  const warningIconColor = useColorModeValue('#D22779', '#38cf0e9');
  const warningColor = useColorModeValue('black', '#38cf0e9');
  const headingColor = useColorModeValue('blue', '#8cf0e9');

  async function getTotalShares() {
    try {
      const contract = new Contract(
        AnimalAllocationAddress,
        AnimalAllocation.abi,
        library.getSigner()
      );

      const totalShares = await contract.getTotalShares(account);
      console.log('totalShares ', totalShares.toString());
      const newTotalShares = parseFloat(formatEther(totalShares)).toFixed(2);
      setTotalShares(newTotalShares);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUnlockedShares() {
    try {
      const contract = new Contract(
        AnimalAllocationAddress,
        AnimalAllocation.abi,
        library.getSigner()
      );

      const unlockedShares = await contract.getUnlockedShares();
      console.log('unlockedShares ', unlockedShares.toString());
      const newUnlockedShares = parseFloat(formatEther(unlockedShares)).toFixed(
        2
      );
      setUnlockedShares(newUnlockedShares);
    } catch (error) {
      console.log(error);
    }
  }

  async function getOutstandingShares() {
    try {
      const contract = new Contract(
        AnimalAllocationAddress,
        AnimalAllocation.abi,
        library.getSigner()
      );

      const outstandingShares = await contract.getOutstandingShares();
      console.log('OutstandingShares ', outstandingShares.toString());

      const newOutStandingShares = parseFloat(
        formatEther(outstandingShares)
      ).toFixed(2);
      setOutStandingShares(newOutStandingShares);
    } catch (error) {
      SetError(error);
      console.log(error);
    }
  }

  async function getWithdrawnShares() {
    try {
      const contract = new Contract(
        AnimalAllocationAddress,
        AnimalAllocation.abi,
        library.getSigner()
      );

      const withdrawnShares = await contract.getWithdrawnShares();
      console.log('withdrawnShares ', withdrawnShares.toString());
      const newWithdrawnShares = parseFloat(
        formatEther(withdrawnShares)
      ).toFixed(2);
      setWithdrawnShares(newWithdrawnShares);
    } catch (error) {
      console.log(error);
    }
  }
  async function withdrawHandler() {
    try {
      setLoading(true);
      const contract = new Contract(
        AnimalAllocationAddress,
        AnimalAllocation.abi,
        library.getSigner()
      );

      const withdrawShare = await contract.withdrawShare();
      await withdrawShare.wait();
      getTotalShares();
      getUnlockedShares();
      getOutstandingShares();
      getWithdrawnShares();
    } catch (err) {
      const data = JSON.stringify(err.message);
      SetError(data);
      toast({
        position: 'top',
        title: 'Try Again',
        description: data,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      console.log(`error is ${data}`);
    }

    setLoading(false);
  }

  useEffect(() => {
    getTotalShares();
    getUnlockedShares();
    getOutstandingShares();
    getWithdrawnShares();
  }, [account, chainId, active]);

  return (
    <Flex
      flexDirection={['column']}
      width={'auto'}
      height='auto'
      borderRadius={'xl'}
    >
      {chainId === 80001 || chainId === 1337 || chainId === 137 ? (
        <Box marginLeft={['unset', 'unset', '20', 'unset']}>
          <Heading
            size={['sm', 'md', 'lg']}
            color={headingColor}
            fontSize={['2xl', '6xl', '6xl', '6xl']}
            textAlign={['center', 'center', 'center', 'center']}
            marginLeft={['70px', 'unset', 'unset', '25px']}
            marginTop={['unset', 'unset', '-40px', 'unset']}
            fontWeight={'thin'}
          >
            Vesting Portal
          </Heading>
          <Text
            color={headingColor}
            fontSize={['20', 'xl', 'xl', '2xl']}
            // fontSize='xl'
            textAlign={['center', 'center', 'center', 'center']}
            marginLeft={['60px', 'unset', 'unset', '25px']}
            marginTop={'2'}
            fontFamily={'Questrial'}
          >
            Your ANML Tokens Summary{' '}
          </Text>
          <Box
            height={['unset', 'unset', '700px', 'unset']}
            marginLeft={['60px', 'auto', 'unset', '0px']}
            alignItems={'center'}
            display={'flex'}
            flexDirection='column'
            gap={['', '5', '5', '5']}
            marginTop={['-25px', 'unset', '-40px', '-10px']}
          >
            <Flex
              display={['grid', 'flex', 'flex', 'flex']}
              marginTop={'20'}
              marginBottom='10'
              flexDirection={'row'}
              flexFlow='row'
              justifyContent={'space-between'}
              borderRadius={'20'}
              gap='10'
            >
              <DetailedInfo title='Total Shares Amount ' state={totalShares} />
              <DetailedInfo
                title='Claimed shares Amount '
                state={withdrawnShares}
              />
            </Flex>
            <Flex
              display={['grid', 'flex', 'flex', 'flex']}
              flexDirection={'row'}
              flexFlow='row'
              borderRadius={'20'}
              gap='10'
            >
              <DetailedInfo
                title='Outstanding Shares Amount '
                state={outstandingShares}
              />
              <DetailedInfo
                title=' Claimable Shares Amount '
                state={unlockedShares}
              />{' '}
            </Flex>
            <Button
              disabled={
                unlockedShares <= 0 ||
                !(
                  unlockedShares ||
                  outstandingShares ||
                  withdrawnShares ||
                  totalShares
                ) ||
                loading
              }
              isLoading={loading}
              loadingText={<Text>Please Wait...</Text>}
              marginTop={'5'}
              variant={'solid'}
              width={['210px', '350px', '350px', '350px']}
              height={['50px', '80px', '80px', '80px']}
              borderRadius={'2xl'}
              onClick={withdrawHandler}
            >
              <Text
                fontSize={['18px', '30px', '30px', '30px']}
                fontFamily={'Questrial'}
                fontWeight='extrabold'
              >
                CLAIM ANML{' '}
              </Text>
            </Button>
          </Box>
        </Box>
      ) : (
        <Flex
          margin={'10'}
          gap={10}
          direction={'column-reverse'}
          justifyContent='center'
          alignContent={'center'}
          alignItems='center'
        >
          <Text
            color={warningColor}
            fontSize={'3xl'}
            textAlign={'center'}
            fontWeight={'thin'}
            fontFamily={'Questrial'}
          >
            Please switch your wallet to Polygon Mainnet or Mumbai testnet
            network.
          </Text>

          <WarningTwoIcon color={warningIconColor} boxSize={'20'} />
        </Flex>
      )}
    </Flex>
  );
};

export default SummaryCard;
