import React from 'react';
import { Flex, useColorModeValue, Image } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import img from '../../assets/file-20210806-17-jibbct.jpg';
import SummaryCard from '../UI/SummaryCard';
import HeadingCard from '../UI/HeadingCard';
const Home = () => {
  const { account } = useWeb3React();
  return (
    <Flex
      padding={['15', '15', '10', '15']}
      bg={useColorModeValue('white', '#070b2f')}
      width={'auto'}
    >
      <Flex
        gap={['unset', 5, 5, '5', 95]}
        // width={600}
        height={['100vh', 600, 500, 600]}
        flexDirection={'row'}
        alignItems={['center', 'unset']}
      >
        <Flex
          display={['none', 'none', account ? 'none' : 'flex', 'unset']}
          width={['auto', 'auto', 'auto', '400px', '650px']}
          height={['600px', '600px', account ? 'none' : '400px', '600px']}
          borderRadius={'xl'}
        >
          <Image
            src={img}
            borderRadius={'3xl'}
            width={['inherit', '300px', 'unset', '600px']}
            height={['inherit', '300px', 'inherit', '600px']}
          />
        </Flex>
        {account ? <SummaryCard /> : <HeadingCard />}
      </Flex>
    </Flex>
  );
};
export default Home;
