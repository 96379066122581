import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaReddit,
  FaTwitter,
} from 'react-icons/fa';

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target='_blank'
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer({ children }) {
  const boxColor = useColorModeValue('white', '#070b2f');
  const headingColor = useColorModeValue('blue', '#8cf0e9');

  return (
    <Box
      zIndex={10}
      as='nav'
      align='center'
      justify='space-between'
      wrap='wrap'
      bg={boxColor}
    >
      <Container
        as={Stack}
        maxW={'8xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text
          color={headingColor}
          fontSize={['12', '16px', '16px', '20px']}
          textAlign={['right', 'right', 'right', 'right']}
          fontFamily={'Questrial'}
        >
          {' '}
          © 2022 Animal Concerts. All rights reserved
        </Text>{' '}
        <Stack direction={'row'} spacing={6}>
          <SocialButton
            label={'facebook'}
            href={'https://www.facebook.com/AnimalConcerts/'}
          >
            <FaFacebook />
          </SocialButton>

          <SocialButton
            label={'Twitter'}
            href={'https://twitter.com/animalconcerts'}
          >
            <FaTwitter />
          </SocialButton>
          <SocialButton
            label={'instagram'}
            href={'https://www.instagram.com/animalconcerts/'}
          >
            <FaInstagram />
          </SocialButton>
          <SocialButton
            label={'Discord'}
            href={'https://discord.gg/nYW5aFwQcF'}
          >
            <FaDiscord />
          </SocialButton>
          <SocialButton
            label={'Reddit'}
            href={'https://www.reddit.com/r/AnimalConcerts/'}
          >
            <FaReddit />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
