import '@fontsource/alfa-slab-one';
import '@fontsource/questrial';

import { Heading, Text, Box, Flex, useColorModeValue } from '@chakra-ui/react';
import Account from '../Account/Account';

const HeadingCard = () => {
  const headingColor = useColorModeValue('blue', '#8cf0e9');
  return (
    <Flex
      marginTop={['', 'unset', 'unset', 'unset']}
      marginBottom={['', 'unset', 'unset', 'unset']}
      ml={['0', '10', '', '']}
      padding={['5', '10', '10', '25']}
      width={['500', '300', '600', '600']}
      height={['500', '300', '600', '600']}
      flexDirection={'column'}
      gap={['4', '8', '8', '10']}
      borderRadius={'xl'}
    >
      <Box
        justifyContent={'center'}
        alignContent='center'
        alignItems={'center'}
        // marginLeft={['', '0', '0', '90px']}
        marginLeft={{ base: '0', md: '0', lg: '-48px' }}
      >
        <Heading
          textAlign={['center', 'right', 'right', 'right']}
          color={headingColor}
          fontWeight={'light'}
          fontSize={['3rem', '3xl', '3xl', '6xl']}
        >
          Animal Concerts
        </Heading>
        <Heading
          color={headingColor}
          textAlign={['center', 'right', 'right', 'right']}
          fontSize={['2rem', '2xl', '2xl', '4xl']}
          fontWeight={'thin'}
        >
          Vesting Portal
        </Heading>
        <Text
          color={headingColor}
          fontFamily={'Questrial'}
          marginTop={'5'}
          size={'md'}
          // marginLeft={['auto', 'unset', 'unset', 'unset']}
          textAlign={['center', 'right', 'right', 'right']}
          fontSize={['15px', '16px', '16px', '20px']}
        >
          Let us detect if you have unclaimed ANML
        </Text>
        <Text
          color={headingColor}
          fontSize={['15px', '16px', '16px', '20px']}
          textAlign={['center', 'right', 'right', 'right']}
          fontFamily={'Questrial'}
        >
          {' '}
          by Connecting to your wallet first
        </Text>
      </Box>
      <Flex justify={['center', 'end', 'flex-end', 'end']}>
        <Account main />
      </Flex>
    </Flex>
  );
};

export default HeadingCard;
