import React from 'react';
import { getEllipsisTxt } from '../../Helpers/formatters';
import { useEffect, useState } from 'react';
import { getExplorer } from '../../Helpers/networks';
import {
  connectors,
  walletconnect,
  resetWalletConnector,
  injected,
  walletlink,
  fortmatic,
} from './config';
import '@fontsource/questrial';
import '@fontsource/alfa-slab-one';
import { useWeb3React } from '@web3-react/core';
import {
  Box,
  Flex,
  Text,
  Link,
  Button,
  useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useToast,
  ModalBody,
  ModalCloseButton,
  useClipboard,
} from '@chakra-ui/react';
import { CheckCircleIcon, CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { ethers } from 'ethers';

const styles = {
  account: {
    height: '42px',
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '12px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer',
  },

  connector: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 5px',
    cursor: 'pointer',
  },
  icon: {
    alignSelf: 'center',
    fill: 'rgb(40, 13, 95)',
    flexShrink: '0',
    marginBottom: '8px',
    height: '40px',
  },
};

function Account(props) {
  const { account, activate, deactivate, chainId } = useWeb3React();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(account);
  const [error, setError] = useState('');

  const linkColor = useColorModeValue('#d22779', '#8f96ac');
  const buttonColor = useColorModeValue('#d22779', '#8f96ac');

  const ACCOUNT_KEY = 'LAST_ACTIVE_ACCOUNT';
  const CONNECTOR_KEY = 'LAST_WALLET_CONNECTOR';

  // console.log(getExplorer.chain);
  const connetHandler = async (provider) => {
    try {
      if (provider === walletconnect) {
        resetWalletConnector(walletconnect);
        await activate(provider);
      }
      if (provider === injected) {
        if (window.ethereum === undefined) {
          alert('Please install metamask');
        }
        if (window.ethereum.networkVersion !== 137) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: ethers.utils.hexlify(137) }],
            });
          } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'Polygon Mainnet',
                    chainId: ethers.utils.hexlify(137),
                    nativeCurrency: {
                      name: 'MATIC',
                      decimals: 18,
                      symbol: 'MATIC',
                    },
                    rpcUrls: ['https://polygon-rpc.com/'],
                  },
                ],
              });
            }
          }
        }
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          'any'
        );
        // Prompt user for account connections
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const last = await signer.getAddress();
        setLastConnector(last);
        activate(injected);

        setLastActiveAccount(last);
      }

      if (provider === walletlink) {
        await activate(provider);
      }
      if (provider === fortmatic) {
        await activate(provider);
      }
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  };

  const setLastActiveAccount = (account) => {
    localStorage?.setItem(ACCOUNT_KEY, account);
  };

  const clearLastActiveAccount = () => {
    localStorage?.removeItem(ACCOUNT_KEY);
  };

  const getLastActiveAccount = () => {
    return localStorage?.getItem(ACCOUNT_KEY);
  };

  const setLastConnector = (connector) => {
    localStorage?.setItem(CONNECTOR_KEY, connector);
  };

  const getLastConnector = () => {
    return localStorage?.getItem(CONNECTOR_KEY);
  };

  useEffect(() => {
    const lastConnector = getLastConnector();
    const lastActiveAccount = getLastActiveAccount();

    if (lastActiveAccount && lastConnector) {
      activate(injected);
    }

    //eslint-disable-next-line
  }, []);
  if (!account) {
    return (
      <>
        <Button
          onClick={onOpen}
          variant={'solid'}
          size={props.nav && 'md'}
          width={props.main && ['180px', '180px', '280px', '300px']}
          height={props.main && ['50px', '40px', '50px', '60px']}
          mr={4}
          borderRadius={['2xl', '2xl', '2xl', '2xl']}
          fontFamily={'Questrial'}
          fontWeight={'extrabold'}
        >
          <Text
            fontSize={props.main ? ['18', '18', '20', '25'] : 'md'}
            fontFamily={'Questrial'}
            fontWeight='extrabold'
          >
            CONNECT WALLET
          </Text>
        </Button>

        <Modal
          motionPreset='slideInBottom'
          size={'md'}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <Box
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Text
                fontSize='20px'
                fontWeight={'semibold'}
                fontFamily={'Questrial'}
              >
                {' '}
                Connect Wallet
              </Text>
            </Box>{' '}
            <ModalCloseButton />
            <ModalBody>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                {connectors.map(({ title, icon, provider }, key) => (
                  <div
                    style={styles.connector}
                    key={title}
                    onClick={() => {
                      connetHandler(provider);
                      toast({
                        position: 'top',
                        title: 'Initializing...',
                        // description: 'we are Proccesing your request',
                        status: 'info',
                        duration: 1000,
                        isClosable: true,
                      });
                    }}
                  >
                    <img src={icon} alt={title} style={styles.icon} />
                    <Text fontFamily={'Questrial'} style={{ fontSize: '16px' }}>
                      {title}
                    </Text>
                  </div>
                ))}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      {chainId === 1337 || chainId === 137 || chainId === 80001 ? (
        <Button
          variant={'solid'}
          size={'md'}
          mr={4}
          borderRadius={'2xl'}
          onClick={() => setIsModalVisible(true)}
        >
          <Text fontFamily={'Questrial'}>{getEllipsisTxt(account, 5)}</Text>
        </Button>
      ) : (
        <Button
          variant={'solid'}
          size={'md'}
          mr={4}
          borderRadius={'2xl'}
          onClick={async () => {
            if (window.ethereum.networkVersion !== 137) {
              try {
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: ethers.utils.hexlify(137) }],
                });
              } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainName: 'Polygon Mainnet',
                        chainId: ethers.utils.hexlify(137),
                        nativeCurrency: {
                          name: 'MATIC',
                          decimals: 18,
                          symbol: 'MATIC',
                        },
                        rpcUrls: ['https://polygon-rpc.com/'],
                      },
                    ],
                  });
                }
              }
            }
          }}
        >
          <Text color={'pink'} fontFamily={'Questrial'}>
            WRONG NETWORK
          </Text>
        </Button>
      )}
      <Modal
        motionPreset='slideInBottom'
        veriant='wide'
        isCentered
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        size={'sm'}
      >
        <ModalOverlay />
        <ModalContent borderRadius='3xl'>
          <ModalHeader px={4} fontSize='lg' fontWeight='medium'>
            Account
          </ModalHeader>
          <ModalCloseButton fontSize='sm' />
          <ModalBody pt={0} px={4}>
            <Box borderRadius='3xl' border='1px' px={5} pt={4} pb={2} mb={3}>
              <Flex justifyContent='space-between' alignItems='center' mb={3}>
                <Text fontSize='md' color={buttonColor} fontWeight={'normal'}>
                  Connected with Wallet
                </Text>
                <Button
                  color={buttonColor}
                  variant='outline'
                  size='sm'
                  borderColor='blue.800'
                  borderRadius='3xl'
                  fontSize='13px'
                  fontWeight='normal'
                  px={2}
                  height='26px'
                  onClick={() => {
                    deactivate();
                    clearLastActiveAccount();
                  }}
                >
                  Change
                </Button>
              </Flex>
              <Flex alignItems='center' mt={2} mb={4} lineHeight={1}>
                <Text
                  fontSize='xl'
                  fontWeight='semibold'
                  ml='4'
                  lineHeight='1.1'
                >
                  {account && getEllipsisTxt(account, 5)}
                </Text>
              </Flex>
              <Flex alignContent='center' width='200'>
                <Button
                  textDecoration={'none'}
                  _hover={{
                    textDecoration: 'none',
                    color: linkColor,
                  }}
                  _focus={{
                    border: 'none',
                    textDecoration: 'none',
                  }}
                  onClick={onCopy}
                  fontSize={'16px'}
                  fontWeight={'normal'}
                  variant='ghost'
                  color={linkColor}
                >
                  {hasCopied ? <CheckCircleIcon /> : <CopyIcon />}
                  {hasCopied ? ` copied` : `copy Address`}
                </Button>
                <Link
                  fontWeight={'normal'}
                  color={linkColor}
                  fontSize='16px'
                  display='flex'
                  alignItems='center'
                  href={`${getExplorer(chainId)}/address/${account}`}
                  isExternal
                  marginLeft={'3'}
                >
                  <Text> View on Explorer</Text>{' '}
                  <ExternalLinkIcon marginLeft={'.2px'} />
                </Link>
              </Flex>
            </Box>
            <Button
              variant={'solid'}
              style={{
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px',
                borderRadius: '0.8rem',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
              onClick={() => {
                deactivate();
                setIsModalVisible(false);
                clearLastActiveAccount();
              }}
            >
              <Text fontFamily={'Questrial'}> Disconnect </Text>{' '}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Account;

// Modal
//         visible={isModalVisible}
//         footer={null}
//         onCancel={() => setIsModalVisible(false)}
//         bodyStyle={{
//           padding: "15px",
//           fontSize: "17px",
//           fontWeight: "500",
//         }}
//         style={{ fontSize: "16px", fontWeight: "500" }}
//         width="400px"
//       >
//         Account
//         <Card
//           style={{
//             marginTop: "10px",
//             borderRadius: "1rem",
//           }}
//           bodyStyle={{ padding: "15px" }}
//         >
//           <Address
//             avatar="left"
//             size={6}
//             copyable
//             style={{ fontSize: "20px" }}
//           />
//           <div style={{ marginTop: "10px", padding: "0 10px" }}>
//             <a
//               href={`${getExplorer(chainId)}/address/${account}`}
//               target="_blank"
//               rel="noreferrer"
//             >
//               <SelectOutlined style={{ marginRight: "5px" }} />
//               View on Explorer
//             </a>
//           </div>
//         </Card>
//         <Button
//           size="large"
//           type="primary"
//           style={{
//             width: "100%",
//             marginTop: "10px",
//             borderRadius: "0.5rem",
//             fontSize: "16px",
//             fontWeight: "500",
//           }}
//           onClick={async () => {
//             await logout();
//             window.localStorage.removeItem("connectorId");
//             setIsModalVisible(false);
//           }}
//         >
//           Disconnect Wallet
//         </Button>
//       </Modal>
