import { Link as ReactLink } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import {
  Flex,
  useColorModeValue,
  Heading,
  Text,
  Image,
  Center,
  Link,
} from '@chakra-ui/react';
import ErrorImg from '../../assets/bg-404.png';

const NotFound = () => {
  const { account } = useWeb3React();
  const hoverColor = useColorModeValue('blue.500', 'cyan.500');
  const linkColor = useColorModeValue('#532DF5', 'white');
  const headingColor = useColorModeValue('blue', '#8cf0e9');

  return (
    <Flex
      height='100vh'
      justifyContent={'center'}
      alignItems='center'
      alignContent={'center'}
      bg={useColorModeValue('white', '#070b2f')}
    >
      <Center
        justifyContent={'center'}
        alignItems='center'
        alignContent={'center'}
        flexDirection={'column'}
      >
        <Image
          p={'10'}
          width={['200px', '300px', '300px', '300px']}
          height={['200px', '300px', '300px', '300px']}
          src={ErrorImg}
          alt='Eror message'
        ></Image>
        <Heading
          marginTop={'5'}
          textAlign={['center', 'right', 'right', 'right']}
          color={headingColor}
          fontWeight={'light'}
          fontSize={['1.8rem', '3xl', '3xl', '6xl']}
        >
          Error 404
        </Heading>
        <Text
          marginTop={'5'}
          color={headingColor}
          fontSize={['12', '16px', '16px', '20px']}
          textAlign={['center', 'right', 'right', 'right']}
          fontFamily={'Questrial'}
        >
          {' '}
          We can’t seem to find the page you’re looking for.
        </Text>{' '}
        <Link
          fontWeight={'bold'}
          color={linkColor}
          variant={'nav'}
          as={ReactLink}
          _focus={{
            border: 'none',
            color: hoverColor,
          }}
          _hover={{
            color: hoverColor,
          }}
          fontSize={'lg'}
          to='/'
        >
          <Text mt='2px' fontFamily={'Questrial'}>
            Go back to homepage
          </Text>
        </Link>
      </Center>
    </Flex>
  );
};
export default NotFound;
