/* eslint-disable react-hooks/rules-of-hooks */
// theme.js
import { extendTheme } from '@chakra-ui/react';
import '@fontsource/alfa-slab-one';
import '@fontsource/questrial';

const theme = extendTheme({
  components: {
    Button: {
      // 3. We can add a new visual variant
      variants: {
        'with-shadow': {
          bg: 'red.400',
          boxShadow: '0 0 2px 2px #efdfde',
        },
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? '#532DF5' : '#d22779',
          color: props.colorMode === 'dark' ? 'white' : 'white',
          _hover: {
            bg: props.colorMode === 'dark' ? '#3313BB' : '#E60965',
          },
        }),
        // _hover={{
        //   bg: useColorModeValue('#3313BB', '#3313BB'),
        // }}
        // color={useColorModeValue('white', 'white')}
        // bg={useColorModeValue('#532DF5', '#532df5')}

        // 4. We can override existing variants
      },
    },
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: props.colorMode === 'dark' ? '#070b2f' : 'white',
          borderRadius: '20px',
          color: props.colorMode === 'dark' ? 'white' : 'black',
        },
      }),
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Alfa Slab One',
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Questrial',
      },
    },
  },
});

export default theme;
