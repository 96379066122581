import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Pages/Home';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import NotFound from './components/Pages/NotFound';
import Footer from './components/Footer';

function App() {
  return (
    <Box>
      <Navbar />{' '}
      <Routes>
        <Route path='*' element={<NotFound />} />

        <Route path='/' exact element={<Home />} />
      </Routes>
      <Footer />
    </Box>
  );
}

export default App;
