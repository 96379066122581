import React from 'react';
import { Flex, useColorModeValue, Text, Spinner } from '@chakra-ui/react';

export default function DetailedInfo(props) {
  return (
    <Flex
      flexDirection={'column'}
      width={['170px', '230px', '230px', '250px']}
      height={['60px', '90px', '90px', '100px']}
      bg={useColorModeValue('blue', '#8cf0e9')}
      borderRadius={'xl'}
      alignContent='center'
      justifyContent={'center'}
      alignItems='center'
    >
      <Text
        align={'center'}
        color={useColorModeValue('#8cf0e9', '#070b2f')}
        fontSize={['12', '15', '25', '18']}
        fontWeight='bold'
      >
        {' '}
        {props.state >= 0 ? `${props.state} ANML` : <Spinner />}
      </Text>
      <Text
        align={'center'}
        color={useColorModeValue('#8cf0e9', 'black')}
        marginTop={'2'}
        fontWeight='medium'
        fontSize={['12', '16', '16', '16']}
      >
        {props.title}
      </Text>
    </Flex>
  );
}
